import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'screens/shared/Layout'
import Head from 'components/modules/Head'
import BlogNav from 'components/blog-nav'
import PostCard from 'components/post-card'
import { Row, Column, container } from 'components/core/Layout'
import { Card } from 'components/primitives'
import BlockLink from 'components/core/BlockLink'
import Text from 'components/core/Text'
import Heading from 'components/core/Heading'

export default ({
  data: {
    posts,
    featuredPost: { edges }
  },
  ...props
}) => {
  const featuredPost = edges[0].node
  return (
    <Layout>
      <Head title='블로그' />
      <BlogNav />
      <Row pt={5} {...container}>
        <Column width={[1, null, null, 2 / 3]} mb={[3, null, null, 5]}>
          <BlockLink to={`/blog/${featuredPost.slug}`}>
            <Card
              py={195}
              color='white'
              bg='muted'
              css={{
                backgroundImage: `url(${featuredPost.feature_image})`,
                backgroundSize: 'cover'
              }}
            />
          </BlockLink>
        </Column>
        <Column width={[1, null, null, 1 / 3]} mb={[4, 5]}>
          <Text fontSize={1} mb={3}>
            {featuredPost.primary_tag.name}
          </Text>
          <BlockLink to={`/blog/${featuredPost.slug}`}>
            <Heading fontWeight='heavy' fontSize={[4, null, null, 5]} mb={2}>
              {featuredPost.title}
            </Heading>
            <Text>{featuredPost.excerpt}</Text>
          </BlockLink>
        </Column>
        {posts.edges.map(({ node }) => (
          <Column width={[1, 1 / 2, null, 1 / 3]} key={node.uuid}>
            <PostCard post={node} mb={[4, 5]} />
          </Column>
        ))}
      </Row>
    </Layout>
  )
}

export const query = graphql`
  {
    posts: allGhostPost(sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          title
          slug
          uuid
          feature_image
          featured
          excerpt
          published_at
          primary_tag {
            slug
            name
          }
          primary_author {
            slug
            name
          }
        }
      }
    }
    featuredPost: allGhostPost(limit: 1, filter: { featured: { eq: true } }) {
      edges {
        node {
          title
          slug
          uuid
          feature_image
          featured
          excerpt
          published_at
          primary_tag {
            slug
            name
          }
          primary_author {
            slug
            name
          }
        }
      }
    }
  }
`
